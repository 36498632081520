import React from "react";
import logo_krizmi from "./quranStoriesLogo.png";
import styles from './css/css/style.module.css';

function Header({ showLoginBtn = true }) {
  return (
    <header id="header-section">
      <nav className={`${styles['navbar-expand-lg']} ${styles['pl-3']} ${styles['pl-sm-0']} ${styles['navbar']} `} id="navbar" style={{height: "30px", backgroundColor:'white'}}>
        <div className={`${styles['container']} ${styles['d-flex']} ${styles['justify-content-between']} ${styles['align-items-center']}`}>
          <div className={`${styles['navbar-brand-wrapper']} ${styles['d-flex']} ${styles['align-items-center']}`} style={{alignContent:'center', alignItems:'center'}}>
          <a href="#" rel="noopener noreferrer" style={{textAlign:"center"}}>
        <img src={logo_krizmi} style={{ height: "55px", display:"inline", paddingBottom:"10px" }} alt="" />
        <span style={{fontSize: 35,   color:'black',   paddingLeft:"6px", marginTop:'55px'}}>Quran Stories</span>
      </a>
            <button
              className={`${styles['navbar-toggler']} ${styles['ml-auto']}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className={`${styles['mdi']} ${styles['mdi-menu']} ${styles['navbar-toggler-icon']}`}></span>
            </button>
          </div>
          <div
            className={`${styles['collapse']} ${styles['navbar-collapse']} ${styles['navbar-menu-wrapper']}`}
            id="navbarSupportedContent"
          >
            <ul className={`${styles['navbar-nav']} ${styles['align-items-lg-center']} ${styles['align-items-start']} ${styles['ml-auto']}`}>
              <li className={`${styles['d-flex']} ${styles['align-items-center']} ${styles['justify-content-between']} ${styles['pl-4']} ${styles['pl-lg-0']}`}>
                <div className={styles['navbar-collapse-logo']}>
                  <img src={logo_krizmi} style={{ height: "55px" }} alt="" />
                </div>
                <button
                  className={`${styles['navbar-toggler']} ${styles['close-button']}`}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className={`${styles['mdi']} ${styles['mdi-close']} ${styles['navbar-toggler-icon']} ${styles['pl-5']}`}></span>
                </button>
              </li>
              {/* TODO: UNCOMMENT THIS TO SHOW LOGIN */}
              {showLoginBtn && (<li className={`${styles['nav-item']} ${styles['btn-contact-us']} ${styles['pl-4']} ${styles['pl-lg-0']}`}>
                {/* <button
                  className={`${styles['btn']} ${styles['btn-warning']}`}
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => {
                    // window.open("/login/");
                    window.location.href = "/login/";
                  }}
                  style={{backgroundColor:'#5a5f99', borderColor:"#5a5f99"}}
                >
                  Log In
                </button> */}
              </li>)}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
