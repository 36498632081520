import "./App.css";
import LandingPage from "./pages/landingPage/LandingPage";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


import { useEffect } from 'react';
import $ from 'jquery';

function App() {
  useEffect(() => {
    function handleResize() {
      const windowWidth = $(window).width();
      if (windowWidth < 1010) {
        $('body').addClass('small-device');
      } else {
        $('body').removeClass('small-device');
      }
    }

    $(window).on('load resize', handleResize);

    function handleMenuToggle(event) {
      event.stopPropagation(); // prevent the click event from propagating
      console.log('menu clicked'); // check if the function is being called

      const windowWidth = $(window).width();

      if (windowWidth < 1010) {
        $('body').removeClass('open');

        if (windowWidth < 760) {
          $('#left-panel').slideToggle();
        } else {
          $('#left-panel').toggleClass('open-menu');
        }
      } else {
        $('body').toggleClass('open');
        $('#left-panel').removeClass('open-menu');
      }
    }

    $(document).ready(() => {
      $('#menuToggle').on('mousedown', handleMenuToggle);

      $('.menu-item-has-children.dropdown').each(function () {
        $(this).on('mousedown', function () {
          const tempText = $(this).children('.dropdown-toggle').html();
          $(this).children('.sub-menu').prepend(`<li class="subtitle">${tempText}</li>`);
        });
      });
    });

    return () => {
      $(window).off('load resize', handleResize);
      $('#menuToggle').off('mousedown', handleMenuToggle);
      $('.menu-item-has-children.dropdown').off('mousedown');
    };
  }, []);
  return (
    
      <Router>
        <Switch>
          <Route path="/" component={LandingPage} exact />
          
        </Switch>
      </Router>
    
  );
}

export default App;
