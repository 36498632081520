import React from "react";

import books from "./images/books.jpg";
import classNotes from "./images/quranStories_chaps_new.png";
import tutor from "./images/tutor.jpg";
import flashcard1 from "./images/flashcard.jpg";
import flashcard2 from "./images/flashcard2.jpg";
import flashcard3 from "./images/flashcard3.jpg";
import quiz1 from "./images/quiz1.jpg";
import quiz2 from "./images/quiz2.jpg";
import quiz3 from "./images/quiz3.jpg";

import quranStories2 from "./images/quranStories2.png";
import quranStories3 from "./images/quranStories3.png";
import quranStories4 from "./images/quranStories4.png";
import quranStories5 from "./images/quranStories5.png";
import quranStories6 from "./images/quranStories6.png";
import quranStories7 from "./images/quranStories7.png";
import quranStories8 from "./images/quranStories8.png";
import quranStories9 from "./images/quranStories9.png";

import f1 from "./images/faces/face20.jpg";
import f2 from "./images/faces/face21.jpg";
import f3 from "./images/faces/face22.jpg";
import f4 from "./images/faces/face2.jpg";

import openai from "./images/companies/openai.png";
import microsoft from "./images/companies/microsoft.png";
import firebase from "./images/companies/firebase.png";
import google from "./images/companies/google.png";

import landing_calendar_2 from "./images/quranStoriesNew.png";
import Modal from "react-bootstrap/Modal";

import Header from "./Header";

import styles from './css/css/style.module.css';



function LandingPage(props) {
  // require("./css/css/style.min.css");
  const [show, setShow] = React.useState(false);

  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }
  return (
    <>
      <Header />
      <br />
      <br />
      {/* <br /> */}
      <div className={styles.banner} style={{ backgroundColor: "white" }}>
        <div className={styles.container}>
        <h4 className={styles["font-weight-semibold"]} style={{ color: "#121212" }}>
        Learn Quranic words through the Stories of Quran & AI!
          </h4>
          
         
          {/* <br />
        <br /> */}
          {/* <span
            className={styles["font-weight-normal"] + " " + styles["text-muted"] + " " + styles["pb-3"]}
            style={{ fontSize: "20px", color: "#5a5f99" }}
          >
            Explore Quranic Stories with AI and Learn New Quranic Words!
            
          </span> */}
          


          <img
            src={landing_calendar_2}
            style={{
              // height: "60%",
              // width: "90%",
              height: "700px",
              borderRadius: "10px",
              // boxShadow: "0px 3px 2px rgba(0, 0, 0, 0.2)"
            }}
            alt=""
            className={styles["img-fluid"]}
          />


          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `
          <!-- Begin Mailchimp Signup Form -->
          <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
          <style type="text/css">
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 400px;
          }
          
          .mc-field-group {
            display: inline-block;
            
          }
          
          #mc-embedded-subscribe {
            background-color: orange;
            height: 30px;
            line-height: 30px;
            padding: 0 0px;
            border: none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            margin-top: -10px;
            margin-left: 0px;
            width:345px
          }
            
            
          </style>
          <div id="mc_embed_signup">
              <form action="https://gmail.us21.list-manage.com/subscribe/post?u=01cbb7db04dddf57218fa6ba6&amp;id=41089e27a6&amp;f_id=00ebb2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <div id="mc_embed_signup_scroll">
                  
                  
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
          </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
            <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
          </div>
            <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_01cbb7db04dddf57218fa6ba6_41089e27a6" tabindex="-1" value=""></div>
                  <div class="optionalParent">
                      <div class="clear foot">
                          <input type="submit" value="Get the Early-Access Version!" name="subscribe" id="mc-embedded-subscribe" >
                      </div>
                  </div>
              </div>
          </form>
          </div>
          <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
          <!--End mc_embed_signup-->
      `,
              }}
            />
          </div>


          <br />


          {/* <a href={`https://forms.gle/zF9X2N3FgY9bQKSs6`} target="_blank" style={{color:"white", backgroundColor:"#5a5f99", fontSize:"14px", padding:"3px", borderRadius:"3px"}}>
                <i className="menu-icon fa fa-sign-in"></i> Contribute to Krizmi with Your Feedback!
          </a> */}
          {/* <br/><br/><br/> */}
        </div>
      </div>


      <div className={styles["banner"]} style={{ backgroundColor: "white" }}>
        <div className={styles["container"]}>
          <section className={styles["features-overview"]} id="features-section">
            <div className={styles["content-header"]} style={{ padding: "15px" }}>
              <h2 style={{ color: "#121212" }}>Engaging Story Based Learning</h2>
              <h6 className={styles["section-subtitle"] + " " + styles["text-muted"]} style={{ color: "#5a5f99" }}>
                Discover Quranic words within engaging stories. Understand meanings in context, making memorization easier and Quran exploration fascinating.
              </h6>
            </div>
          </section>
          <br />


          <section
            className={styles['digital-marketing-service']}
            id="digital-marketing-section2"
          // style={{  marginTop: "-15px" }}
          >
            <div className={styles["row"]}>
              <div className={styles["row"]}>

                <div className={styles['col-md-4']}>
                  <img
                    src={classNotes}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>Lots of Stories from the Quran!</h6>
                </div>


                <div className={styles['col-md-4']}>
                  <img
                    src={quranStories2}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>AI generated images to assist memorization visually!</h6>
                </div>

                <div className={styles['col-md-4']}>
                  <img
                    src={quranStories3}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>Gamified Fun Learning!</h6>
                </div>

              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </div>
      <br /><br />
      <div className={styles["banner"]} style={{ backgroundColor: "white" }}>
        <div className={styles["container"]}>
          <section className={styles["features-overview"]} id="features-section">
            <div className={styles["content-header"]} style={{ padding: "15px" }}>
              <h2 style={{ color: "#121212" }}>Modular Learning Approach</h2>
              <h6 className={styles["section-subtitle"] + " " + styles["text-muted"]} style={{ color: "#5a5f99" }}>
                Enjoy bite-sized episodes for effortless learning. Break down complex stories into manageable lessons, empowering you to learn at your own pace.</h6>
            </div>
          </section>
          <br />


          <section
            className={styles['digital-marketing-service']}
            id="digital-marketing-section2"
          // style={{  marginTop: "-15px" }}
          >
            <div className={styles["row"]}>
              <div className={styles["row"]}>

                <div className={styles['col-md-4']}>
                  <img
                    src={quranStories4}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>Story organized in Episodes!</h6>
                </div>

                <div className={styles['col-md-4']}>
                  <img
                    src={quranStories5}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>Listen to the recitation to immerse yourself in learning!</h6>
                </div>

                <div className={styles['col-md-4']}>
                  <img
                    src={quranStories6}
                    alt="Image 2"
                    className={styles["img-fluid"] + " " + styles["image"]}
                    style={{
                      height: "700px",
                    }}
                  />
                  <h6 className={styles["heading"]}>Build up on your learned Quranic words!</h6>
                </div>

              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </div>


      <div className={styles['banner']} style={{ backgroundColor: "white" }}>
        <div className={styles['container']}>
          <section className="features-overview" id="features-section">
            <div className="content-header" style={{ padding: "15px" }}>
              <h2 style={{ color: "#121212" }}>Fun Learning Activities!</h2>
              <h6 className="section-subtitle text-muted">
                Enjoy quizzes, word games, and repetition techniques. Have fun while reinforcing your learning and remembering what you've studied.
              </h6>
            </div>
          </section>
          <br />
          <br />

          <section
            className={styles['digital-marketing-service']}
            id="digital-marketing-section2"
          // style={{  marginTop: "-15px" }}
          >
            <div className={styles["row"]}>
              <div className={styles['col-md-4']}>
                <img
                  src={quranStories7}
                  alt="Image 2"
                  className={styles["img-fluid"] + " " + styles["image"]}
                  style={{
                    height: "700px",
                  }}
                />
                <h6 className={styles["heading"]}>Take Quiz to Test your Learning!</h6>
              </div>

              <div className={styles['col-md-4']}>
                <img
                  src={quranStories8}
                  alt="Image 2"
                  className={styles["img-fluid"] + " " + styles["image"]}
                  style={{
                    height: "700px",
                  }}
                />
                <h6 className={styles["heading"]}>Gamified Way of Memorization!</h6>
              </div>


              <div className={styles['col-md-4']}>
                <img
                  src={quranStories9}
                  alt="Image 2"
                  className={styles["img-fluid"] + " " + styles["image"]}
                  style={{
                    height: "700px",
                  }}
                />
                <h6 className={styles["heading"]}>More Gamified Way of Memorization...</h6>
              </div>


            </div>
            <br />
            {/* <br />
            <br />
            <br /> */}
          </section>
        </div>
      </div>





      <footer className="border-top d-flex flex-column justify-content-center">
        

        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `
          <!-- Begin Mailchimp Signup Form -->
          <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
          <style type="text/css">
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 400px;
          }
          
          .mc-field-group {
            display: inline-block;
            
          }
          
          #mc-embedded-subscribe {
            background-color: orange;
            height: 30px;
            line-height: 30px;
            padding: 0 0px;
            border: none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            margin-top: -10px;
            margin-left: 0px;
            width:345px
          }
            
            
          </style>
          <div id="mc_embed_signup">
              <form action="https://gmail.us21.list-manage.com/subscribe/post?u=01cbb7db04dddf57218fa6ba6&amp;id=41089e27a6&amp;f_id=00ebb2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <div id="mc_embed_signup_scroll">
                  
                  
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
          </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
            <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
          </div>
            <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_01cbb7db04dddf57218fa6ba6_41089e27a6" tabindex="-1" value=""></div>
                  <div class="optionalParent">
                      <div class="clear foot">
                          <input type="submit" value="Get the Early-Access Version!" name="subscribe" id="mc-embedded-subscribe" >
                      </div>
                  </div>
              </div>
          </form>
          </div>
          <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
          <!--End mc_embed_signup-->
      `,
              }}
            />
          </div>

        {/* TODO: UNCOMMENT THIS TO SHOW TRY IT OUT NOW BUTTON */}
        {/* <div className="text-center">
          <button
            className="btn btn-warning btn-sm"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => {
              window.open("/login/");
            }}
            style={{ backgroundColor: "#5a5f99", borderColor: "#5a5f99", color: 'white' }}
          >
            Try it now for Free! (Limited Time)
          </button>
        </div> */}
        <div className="text-center text-muted pt-4">
          Copyright © 2024
          <a href="#" className="px-1">
            Quran Stories
          </a>
          All rights reserved.
          <br />
          Created with{" "}
          <a href="https://www.bootstrapdash.com/" style={{ color: "black" }}>
            Bootstrapdash
          </a>
          ,{" "}
          <a href="https://www.freepik.com/" style={{ color: "black" }}>
            Freepik
          </a>{" "}
          <br />
        </div>
      </footer>

      {/* <footer>
        <a href="https://www.freepik.com/free-vector/student-with-laptop-studying-online-course_7732666.htm#query=student%20laptop%20illustrations&position=21&from_view=search&track=ais">
          Image by pch.vector
        </a>{" "}
        on Freepik
      </footer> */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...</Modal.Body>
      </Modal>
    </>
  );
}

export default LandingPage;
